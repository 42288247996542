// 当前有更多资源权限的
const jurisdiction = [
    "176****0605", //余育权
    '188****1369', //周承橄
    '188****8888', //测试账号
    '139****2481', //林彦岑
    '139****3689', //陈涛
    '178****5934', //吕磊
    '136****2833', //施总
    '182****5561',
    '133****7525',
    '189****1521',
    '195****8582',
    '187****9035',
    '186****6720',
    '139****4494',
    '138****9224',
    '135****2216',
    '180****5408',
    '136****8939',
    '180****8832',
    '150****4192',
    '177****4777',
    '157****4773',
    '132****2713',
    '187****2677',
    '157****9846',
    '150****3796',
    '137****3523',
    '157****1751',
    '159****0862',
    '157****7144',
    '159****5821',
    '198****0265',
    '199****9329',
    '150****3001',
    '130****2851',
    '177****9178',
    '130****7938',
    '173****2186',
    '186****8665',
    '186****4865',
    '173****3972',
    '130****0993',
]
export default jurisdiction