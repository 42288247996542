/** 
 * Vuex.Store主入口
 * @author
 */
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import user from './modules/user';
import permission from './modules/permission';
import tagsView from './modules/tagsView';


export default new Vuex.Store({
  modules: {
    app,    
    user,
    permission,
	tagsView
  },
  getters
});
