import request from './lib/request'

function _request(url, config, method, jsonParam) {
  let _json = { url: url, method: method }
  if (config && config.isLoadingHide) {
    _json.loading = false
    delete config.isLoadingHide
  }
  if (method === 'get' || method === 'delete') {
    if(config) _json.params = config
  } else {
    if(config) _json.data = config
  }
  if (jsonParam) {
    _json.cType = jsonParam
  }
  return request(_json)
}
export default {
  get: function(url, config) {
    return _request(url, config, 'get')
  },
  post: function(url, config, jsonParam) {
    return _request(url, config, 'post', (jsonParam||'form'))
  },
  put: function(url, config, jsonParam) {
    return _request(url, config, 'put', (jsonParam||'form'))
  },
  delete: function(url, config) {
    return _request(url, config, 'delete')
  },
  getDownExcl: function(url, config, responseType, method) {
	let jsonData = { url: url, method:method||'get',cType:'form',responseType:responseType||'blob' }
	if(method==='get') jsonData.params = config
	if(method==='post') jsonData.data = config
    return request(jsonData)
  }
}
