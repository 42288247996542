/** @format */

//浏览器小窗口
export function winOpen(url, left) {
  var lef = (window.screenX || window.screenLeft || 0) + (screen.width - (left || 500)) / 2
  window.open(url, 'newwindow', 'height=500, width=600, top=100, left=' + lef + ', toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no')
}

//拖拽domDrag改成domWidth的宽
export function dragControllerDiv(domDrag, domWidth, min) {
  for (let i = 0; i < domDrag.length; i++) {
    // 鼠标按下事件
    domDrag[i].onmousedown = function (e) {
      //颜色改变提醒
      domDrag[i].style.background = 'rgba(0,0,0,0.2)'
      var startX = e.clientX
      domWidth[i].width = domWidth[i].clientWidth
      // 鼠标拖动事件
      document.onmousemove = function (e) {
        var endX = e.clientX
        var moveLen = domWidth[i].width + (endX - startX) // （endx-startx）=移动的距离。domDrag[i].left+移动的距离=左边区域最后的宽度
        // var maxT = box[i].clientWidth - domDrag[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
        if (moveLen < (min || 100)) moveLen = min || 100 // 左边区域的最小宽度为32px
        if (moveLen > document.body.clientWidth - 150) moveLen = document.body.clientWidth - 150 //右边区域最小宽度为150px

        for (let j = 0; j < domWidth.length; j++) {
          domWidth[j].style.width = moveLen + 'px'
        }
      }
      // 鼠标松开事件
      document.onmouseup = function () {
        //颜色恢复
        domDrag[i].style.background = 'rgba(0,0,0,0)'
        document.onmousemove = null
        document.onmouseup = null
        domDrag[i].releaseCapture && domDrag[i].releaseCapture() //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
      }
      domDrag[i].setCapture && domDrag[i].setCapture() //该函数在属于当前线程的指定窗口里设置鼠标捕获
      return false
    }
  }
}

//复制到电脑
export function copy(text) {
  var tag = document.createElement('input')
  tag.setAttribute('id', 'copy_input')
  tag.value = text
  document.getElementsByTagName('body')[0].appendChild(tag)
  document.getElementById('copy_input').select()
  document.execCommand('copy')
  document.getElementById('copy_input').remove()
}

//防止wait时间内多次点击
export function DbClick(func, wait, immediate) {
  console.log('ddd')
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function throttle(fn, delay = 1000) {
  let timer
  return function () {
    if (timer != null) return
    let context = this
    let args = arguments
    fn.apply(context, args)
    timer = setTimeout(() => {
      timer = null
    }, delay)
  }
}

export function debounce(fn, delay) {
  let timer
  return function () {
    let context = this
    let args = arguments
    if (timer) clearInterval(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

//排序
export function sort(data, name, type) {
  data.sort(function (a, b) {
    if (name === 'updateTime') {
      let aVal = a[name] ? new Date(a[name]).getTime() : 0
      let bVal = b[name] ? new Date(b[name]).getTime() : 0
      return bVal - aVal
    } else if (name === 'createTime') {
      let aVal = a[name] ? new Date(a[name]).getTime() : 0
      let bVal = b[name] ? new Date(b[name]).getTime() : 0
      return bVal - aVal
    } else if (name === 'resourceName') {
      // 按名称排序
      return a[name].localeCompare(b[name])
    } else if (name === 'resourceType') {
      // 文件夹
      return b[name].localeCompare(a[name])
    } else {
      if (type === 'desc') return b[name] - a[name]
      return a[name] - b[name]
    }
  })
  return data
}

//下载图片
export function downloadImg(url) {
  var a = document.createElement('a') // 创建一个a节点插入的document
  var event = new MouseEvent('click') // 模拟鼠标click点击事件
  a.href = url // 将图片的src赋值给a节点的href
  a.dispatchEvent(event) // 触发鼠标点击事件
}

// 下载数据流文件
export function download(blob, name, type) {
  if (window.navigator.msSaveBlob) {
    // for ie 10 and later
    try {
      var blobObject = new Blob([blob], { type: type || 'application/vnd.ms-excel' })
      window.navigator.msSaveBlob(blobObject, name)
    } catch (e) {
      console.log(e)
    }
  } else {
    // 其他浏览器 下载方式
    var reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = function (e) {
      // 转换完成，创建一个a标签用于下载
      var a = document.createElement('a')
      if (name) a.download = name
      a.href = e.target.result
      // 兼容触发click
      var evt = document.createEvent('MouseEvents')
      evt.initEvent('click', true, true)
      a.dispatchEvent(evt)
    }
  }
}

// 列表转成树结构
export const treeData = function (source, id, parentId, children) {
  let cloneData = JSON.parse(JSON.stringify(source)) // 对源数据深度克隆
  if (!children) children = 'children'
  if (!parentId) parentId = 'pId'
  if (!id) id = 'id'
  return cloneData.filter(father => {
    // 循环所有项，并添加children属性
    let branchArr = cloneData.filter(item => father[id] === item[parentId]) // 返回每一项的子级数组
    branchArr.length > 0 ? (father[children] = branchArr) : '' //给父级添加一个children属性，并赋值
    return father[parentId] === 0 // 如果第一层不是parentId=0，请自行修改
  })
}

//获取随机值，包含字母与数字
export function generateRdStr(num) {
  var text = ''
  num = num || 10
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < num; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text
}

// 获取url中对应参数的值
export function getQueryValue(queryName, url) {
  url = url || window.location.href
  if (url.indexOf('?') === -1) {
    return null
  }
  let reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)', 'i')
  let r = url.split('?')[1].match(reg)
  if (r != null) {
    return decodeURI(r[2])
  } else {
    return null
  }
}

export function getNowFormatDate() {
  var date = new Date()
  var seperator1 = '-'
  var seperator2 = ':'
  var month = date.getMonth() + 1
  var strDate = date.getDate()
  var strMinutes = date.getMinutes()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }

  if (strMinutes >= 0 && strMinutes <= 9) {
    strMinutes = '0' + strMinutes
  }
  var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + ' ' + date.getHours() + seperator2 + strMinutes + seperator2 + date.getSeconds()
  return currentdate
}

// 处理unix时间戳，转换为可阅读时间格式
export function dateFormat(unix, format, week) {
  let _format = format || 'yyyy-MM-dd hh:mm:ss'
  unix = unix ? Number(String(unix).substring(0, 10)) * 1000 : new Date().getTime()
  const d = new Date(unix)
  const o = {
    'M+': d.getMonth() + 1,
    'd+': d.getDate(),
    'h+': d.getHours(),
    'm+': d.getMinutes(),
    's+': d.getSeconds(),
    'q+': Math.floor((d.getMonth() + 3) / 3),
    S: d.getMilliseconds()
  }
  if (/(y+)/.test(_format)) _format = _format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) if (new RegExp('(' + k + ')').test(_format)) _format = _format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  let weekTxt = ['日', '一', '二', '三', '四', '五', '六']
  if (week) _format = _format + ' ' + weekTxt[d.getDay()]
  return _format
}

//与当前时间相差多久
function pluralize(time, label) {
  console.log(time)
  if (time === 1) {
    return time + label
  }
  return time + '天'
}
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), '分钟')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), '小时')
  } else {
    return pluralize(~~(between / 86400), '天')
  }
}

export function diffTime(time) {
  const totay_zero = new Date(new Date().toLocaleDateString()).getTime() // 今天0点时间
  const yestoday_zero = totay_zero - 24 * 60 * 60 * 1000 // 昨天0点时间
  const today_diff = new Date(time).getTime() - totay_zero // 与今天0点时间差
  const yes_diff = new Date(time).getTime() - yestoday_zero // 与昨天0点时间差

  const time_month = new Date(time).getMonth() + 1 < 10 ? '0' + (new Date(time).getMonth() + 1) : new Date(time).getMonth() + 1
  const time_day = new Date(time).getDate() < 10 ? '0' + new Date(time).getDate() : new Date(time).getDate()
  const time_hour = new Date(time).getHours() < 10 ? '0' + new Date(time).getHours() : new Date(time).getHours()
  const time_mini = new Date(time).getMinutes() < 10 ? '0' + new Date(time).getMinutes() : new Date(time).getMinutes()
  if (today_diff > 0) {
    return '今天' + ' ' + time_hour + ':' + time_mini
  } else if (today_diff < 0) {
    if (yes_diff > 0) {
      return '昨天' + ' ' + time_hour + ':' + time_mini
    } else {
      return time_month + '-' + time_day + ' ' + time_hour + ':' + time_mini
    }
  }
}
//中文的小提示
export function formatTime(time, format) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()
  const diff = (now - d) / 1000
  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (format) {
    return dateFormat(time, format)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

/* 数字 格式化*/
export function nFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

//数字格式化  2999 => 2,999
export function toThousandslsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

//金钱单位置换  2999 => 2,999.00
export function unitPrice(price, unit) {
  if (typeof price !== 'number') return price
  let priceTxt = String(Number(price).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return (unit || '') + priceTxt
}

//获取label值 str当前值  arr当前数组 label对应的key value对应的val
export function getLabel(str, arr, label, value) {
  label = label || 'label'
  value = value || 'value'
  if (!String(str)) return ''
  const newArr = arr.filter(item => String(item[value]) === String(str))
  const retuenValue = newArr[0] ? newArr[0][label] : ''
  return retuenValue
}

/*
 * 动态加载样式链接
 */
export function loadStyle(url) {
  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = url
  link.type = 'text/css'
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
