<!-- @format -->

<template>
  <div v-if="!routes.hidden" v-show="menuIsShow(routes)">
    <template v-if="!hasChild(routes.children) || routes.path === '/'">
      <div class="menu_hr" v-if="routes.name === 'moreResource'"></div>
      <div class="menu_hr" v-if="routes.name === 'plugin'"></div>
      <app-link :to="getResolvePathUrlRoutes">
        <el-menu-item :index="getResolvePathUrlRoutes">
          <!-- <menu-title :icon="routes.meta.icon || (routes.meta && routes.meta.icon)" :title="routes.meta.title" /> -->
          <template #title>
            <svg class="icon" style="width:20px;height:20px;" aria-hidden="true">
              <use :xlink:href="`#${routes.meta.icon || (routes.meta && routes.meta.icon)}`"></use>
            </svg>
            <span>{{routes.meta.title}}</span>
          </template>
        </el-menu-item>
      </app-link>
    </template>

    
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isExternal } from '@/utils/validate'
import { getBelongTopMenuPath } from '@/utils/permission'
import jurisdiction from '@/data/jurisdiction'
import AppLink from '@/components/Link'
export default {
  name: 'SidebarMenuItem',
  props: {
    // route object
    routes: {
      type: Object,
      required: true
    },
    // 根路径地址
    basePath: {
      type: String,
      required: true
    },
    // 上级路径地址
    resolvePath: {
      type: String,
      default: ''
    },
    activeTopMenu: {
      type: String,
      default: ''
    }
  },
  components: {
    AppLink
  },
  computed: {
    ...mapGetters(['siteTopNavEnable','userInfo']),
    getBasePath: function () {
      return this.basePath
    },
    getActiveTopMenu: function () {
      if (this.activeTopMenu !== '') {
        return this.activeTopMenu
      }
      const route = this.$route
      /* 
            const { meta, path } = route;
            if (meta.belongTopMenu) { 
                return meta.belongTopMenu;
            }
            return '/' + path.split('/')[1];
            */
      return getBelongTopMenuPath(route)
    },
    getResolvePathUrlRoutes: function () {
      return this.resolvePathUrl(this.routes.path === '/' && this.routes.children.length === 1 ? this.routes.children[0].path : this.routes.path)
    }
  },
  methods: {
    menuIsShow(route) {

      //判断是正式环境 当前用户是否在白名单
      let isproduction = process.env.NODE_ENV == "production"
      let userPhone = this.userInfo.telephone || ""
      let hasJuri = jurisdiction.indexOf(userPhone) == -1
      if(route.name == "moreResource" && hasJuri && isproduction){
        return false
      }

      if (!this.siteTopNavEnable) {
        return true
      }
      let activeTopMenu = this.getBasePath
      const { meta } = route
      if (meta.belongTopMenu) {
        activeTopMenu = meta.belongTopMenu
      }
      return activeTopMenu === this.getActiveTopMenu
    },
    hasChild(children = []) {
      const showChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          return true
        }
      })
      if (showChildren.length > 0) {
        return true
      }
      return false
    },
    resolvePathUrl(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      return routePath
    }
  }
}
</script>
