/** @format */

const getters = {
  siteFiexdHeader: state => state.app.siteFiexdHeader,
  siteTopNavEnable: state => state.app.siteTopNavEnable,
  siteSidebarLogo: state => state.app.siteSidebarLogo,
  sidebarOpened: state => state.app.sidebarOpened,
  tagsView: state => state.app.tagsView,
  avatar: state => state.user.avatar,
  msgtotal: state => state.user.msgtotal,
  name: state => state.user.name,
  roles: state => state.user.roles,
  userInfo: state => state.user.userInfo,
  loadData: state => state.user.loadData,
  permission_routes: state => state.permission.routes,
  resourceSpace: state => state.user.resourceSpace,
  needbindAcount: state => state.user.needbindAcount,
  resourceSortColumn: state => state.user.resourceSortColumn,
  creativeSortColumn: state => state.user.creativeSortColumn,
  rightWidth: state => state.app.rightWidth,
  materialDropItem: state => state.app.materialDropItem,
  lastSelNodeInfo: state => state.user.lastSelNodeInfo,
  reloadMaterial: state => state.user.reloadMaterial,
  viewType: state => state.user.listFormat,
  showDagang: state => state.user.showDagang
}
export default getters
