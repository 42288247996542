<!-- @format -->

<template>
  <div id="indexlayout">
    <div id="indexlayout-right" :class="{ 'fiexd-header': siteFiexdHeader }">
      <div class="indexlayout-right-main">
        <layout-index-left />

        <div id="right_body" class="right_body">
          <div class="right_body_content">
            <layout-index-right-top v-if="!$route.meta.hideHeard" />
            <app-main />
          </div>
        </div>
        <!-- <layout-index-right-footer /> -->
      </div>
    </div>
    <!-- <site-settings /> -->
  </div>
</template>
<script>
/**
 * Index 框架
 * @author
 */
import { mapGetters } from "vuex";
import AppMain from "../components/AppMain";
import LayoutIndexLeft from "./LayoutIndexLeft";
import LayoutIndexRightTop from "./LayoutIndexRightTop";
export default {
  name: "LayoutIndex",
  components: {
    AppMain,
    LayoutIndexLeft,
    LayoutIndexRightTop,
  },
  computed: {
    ...mapGetters(["siteFiexdHeader"]),
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

#indexlayout {
  display: flex;
  height: 100vh;
  overflow: hidden;
  min-width: 1020px;
}

#indexlayout-right {
  position: relative;
  flex: 1;
  overflow: hidden;
  background-color: #fff;

  &.fiexd-header {
    display: flex;
    flex-direction: column;

    .indexlayout-right-main {
      flex: 1;
      display: flex;
      overflow: hidden;
    }

    .right_body {
      width: calc(100% - 181px);
      .right_body_content {
        width: 100%;
        height: 100%;
        background: #fff;
        position: relative;
      }
    }
  }
}
</style>
