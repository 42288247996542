/**
 * 系统配置
 * @author
 */

//站点名称
export const siteTitle = '句合逻辑';

export const siteFiexdHeader = true; //是否固定右侧头部(默认配置)
export const siteTopNavEnable = false; //是否启用顶部导航(默认配置)
export const siteSidebarLogo = true; //是否显示侧边栏LOGO(默认配置)
export const tagsView = false; //是否开启页签

//站点本地存储TokenKey
export const siteTokenKey = "Authorization";

//Ajax请求头TokenKey
export const ajaxHeadersTokenKey = "Authorization";

//站点登录路由地址
export const siteLoginRouter = "/login";
 

//ajax请求 - 返回数据 - 不添加前置验证的 URL
export const ajaxResponseNoVerifyUrl = [
    '/client/login', // 用户登录
    '/client/register', // 获取用户信息
	'/client/auth/callback'//第三方登录
];

//服务端登录,请求ajax地址
export const serverLoginUrl = '/client/login';
//获取用户信息接口
export const serverInfoUrl = '/client/member/info';
//获取权限接口
export const serverRoleUrl = '/client/permission'
//获取退出接口
export const serverLogoutUrl = '/logout';
