/** 
 * 权限验证
 * @author
 */
import router from './index';
import store from '@/store';
import { ElMessage } from 'element-plus';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { siteTitle, siteLoginRouter, serverLoginUrl, siteTokenKey } from '@/settings';
import { isExternal } from '@/utils/validate';
import { SGCookies } from '@/service/lib/localToken'

NProgress.configure({ showSpinner: false }); // NProgress Configuration
// 不验证重定向的白名单
const whiteList = [
    siteLoginRouter,"/register","/threeLogin","/newsPage",'/introduce','/newUser',"/creativePage","/resourceSharePage",'/connect/qrconnect','/client/auth/WECHAT','/client/auth/QQ'
];

router.beforeEach(async(to, from, next) => {
    NProgress.start();
    document.title = (to.meta && to.meta.title) + ' - ' + siteTitle;
    if (whiteList.indexOf(to.path) !== -1) {
        next();
    } else {
		if(!SGCookies(siteTokenKey)) return next('/introduce'+(to.query.code&&to.query.state?`?code=${to.query.code}&state=${to.query.state}`:''))
        const hasRoles = store.getters.roles && store.getters.roles.length > 0;
        if (hasRoles) {
            next();
        } else {
            try {
				// 获取用户角色权限
				let user = SGCookies('user') || {}
				// 注意:角色必须是一个对象数组! 例如: ['admin'] or ,['test','edit']
				let roles = await store.dispatch('user/getRole', user.identity);
				// 根据角色与本地对比生成可访问路由映射
				const accessRoutes = await store.dispatch('permission/generateRoutes', roles);
				// 动态添加可访问路由
				// router.addRoutes(accessRoutes);
				accessRoutes.forEach(item=>{
					router.addRoute(item)
				})
                // 设置replace: true，这样导航就不会留下历史记录
                next({ ...to, replace: true });

            } catch (error) {
                console.log(error,"sdferror")
                // 删除Token
                await store.dispatch('user/resetToken');

                ElMessage.error(error || 'Has Error');
                
                // 跳转到登录
                if (isExternal(serverLoginUrl)) {
                    window.location.href = serverLoginUrl;
                } else {
                    next(siteLoginRouter + "?redirect=" + to.fullPath);
                }
                NProgress.done();
            }
        }
    }
});

router.afterEach(() => {
    // finish progress bar
    NProgress.done();
});

