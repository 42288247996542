/**
 * 用户 Store
 *
 * @format
 * @author
 */

 import request from '@/service/getData'
 import { SGCookies, SGToken, removeCookies, SGTorage } from '@/service/lib/localToken'
 // import { resetRouter } from '@/router'
 // import { isExternal } from '@/utils/validate';
 import { serverLoginUrl, serverInfoUrl, serverLogoutUrl, siteLoginRouter } from '@/settings' //serverRoleUrl, siteLoginRouter
 
 const state = {
   msgtotal: 0,
   roles: [],
   userInfo: SGCookies('userInfo'),
   loadData: [],
   resourceSpace: {},
   needbindAcount: false,
   resourceSortColumn: 'updateTime',
   creativeSortColumn: 'updateTime',
   // lastSelNodeInfo: {
   //   materialContent: '新建素材库', //素材库名称
   //   materialId: '', //素材库ID
   //   categoryId: '' //点击的分类id
   // },
   lastSelNodeInfo: SGTorage('lastSelNodeInfo'),
   lastSelTagItem: { labelId: null, labelName: '' },
   reloadMaterial: true,
   showDagang: SGTorage('showDagang') || 1, //是否显示大纲
   listFormat: SGTorage('listFormat') || 1, //创作与文件页面是否显示列表
 }
 const mutations = {
   SET_MSGTOTAL: (state, msgtotal) => {
     state.msgtotal = msgtotal
   },
   SET_USER: (state, userInfo) => {
     state.userInfo = userInfo
   },
   SET_ROLES: (state, roles) => {
     state.roles = roles
   },
   SET_LOADDATA: (state, val) => {
     state.loadData = val
   },
   SET_SPACE: (state, spaceData) => {
     state.resourceSpace = spaceData
   },
   SET_BINDACOUNT: (state, val) => {
     state.needbindAcount = val
   },
   SET_RESOURCESORT: (state, val) => {
     state.resourceSortColumn = val
   },
   SET_NODENAME: (state, val) => {
     state.lastSelNodeInfo = val
     SGTorage('lastSelNodeInfo', val)
   },
   SET_SHOWDAGANG: (state, val) => {
     state.showDagang = val
     SGTorage('showDagang', val)
   },
   SET_RELOADMATERIAL: (state) => {
     state.reloadMaterial = !state.reloadMaterial
   },
   SEL_LASTSELTAGITEM: (state, lastSelTagItem) => {
     state.lastSelTagItem = lastSelTagItem
   },
   SET_PAGEVIEW: (state, val) => {
     state.listFormat = val
     SGTorage('listFormat', val)
   }
 }
 const actions = {
   loadData({ commit }, res) {
     commit('SET_LOADDATA', res)
   },
   editBindStatus({ commit }, res) {
     commit('SET_BINDACOUNT', res)
   },
   // 第三方用户登录
   threeLogin({ commit }, result) {
     return new Promise((resolve, reject) => {
       request
         .get(result.url, result.data)
         .then(response => {
           const { data } = response
           if (data) {
             SGToken(data)
             //获取用户信息
             request.get(serverInfoUrl).then(res => {
               res.data.identity = data.identity
               commit('SET_USER', res.data)
               SGCookies('userInfo', res.data)
               resolve(res.data)
             })
           } else {
             resolve(response)
           }
         })
         .catch(error => {
           reject(error)
         })
     })
   },
   // 用户登录
   login({ commit }, userInfo) {
     const { username, password, code, loginType } = userInfo
     let jsonData = { username, loginType }
     if (loginType === 2) {
       jsonData.password = password
     } else {
       jsonData.code = code
     }
     return new Promise((resolve, reject) => {
       request
         .post(serverLoginUrl, jsonData, 'json')
         .then(response => {
           if (response.code === 500) {
             reject(response)
           } else {
             const { data } = response
             SGToken(data)
             //获取用户信息
             request.get(serverInfoUrl).then(res => {
               res.data.identity = data.identity
               commit('SET_USER', res.data)
               SGCookies('userInfo', res.data)
               resolve(res.data)
             })
           }
         })
         .catch(error => {
           reject(error)
         })
     })
   },
   // 获取角色
   getRole({ commit }) {
     let userInfo = SGCookies('userInfo')
     userInfo = userInfo || {}
     commit('SET_USER', userInfo)
     commit('SET_MSGTOTAL', userInfo.role_id)
     let data = ['adminRouter'] //admin
     commit('SET_ROLES', data)
     return data
     // return new Promise((resolve, reject) => {
     // 	resolve(data)
     // 	console.log(roleType, reject)
     // 	request.get(serverRoleUrl).then(res => {
     // 		//roleType === roleType === 'ADMIN' ? ['adminRouter'] : ||
     // 		res.data = ['adminRouter']
     // 		let munes = ['adminRouter']
     // 		// if (!res.data||!res.data.length) {
     // 		// 	reject('当前用户未设置菜单，请联系管理员操作!')
     // 		// }else{
     // 		// 	munes = res.data.map(item=>item.code)
     // 		// }
     // 		commit('SET_ROLES', munes)
     // 		resolve(munes)
     // 	}).catch(error => {
     // 		reject(error || 'Error')
     // 	})
     // })
   },
   //存储用户信息
   getUser({ commit }, data) {
     commit('SET_USER', data)
     SGCookies('userInfo', data)
   },
   // 用户退出
   logout({ commit }) {
     return new Promise((resolve, reject) => {
       request
         .get(serverLogoutUrl)
         .then(() => {
           commit('SET_ROLES', [])
           removeCookies()
           //resetRouter()
           resolve({ isExternal: false, siteLoginRouter: siteLoginRouter })
         })
         .catch(error => {
           reject(error)
         })
     })
   },
   // 删除Token
   resetToken({ commit }) {
     return new Promise(resolve => {
       commit('SET_ROLES', [])
       removeCookies()
       resolve()
     })
   },
   // 获取用户空间信息
   getSpace({ commit }) {
     return new Promise(resolve => {
       request.get('/client/resource/space', { isLoadingHide: true }, 'json').then(res => {
         let res_size = (res.data / 1024 / 1024).toFixed(1)
         commit('SET_SPACE', { size: res_size + 'M', percentage: (res_size / 1024) * 100 })
         resolve()
       })
     })
   }
 }
 export default {
   namespaced: true,
   state,
   mutations,
   actions
 }
 