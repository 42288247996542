
<template>
  <!-- eslint-disable vue/require-component-is -->
  <a @click.stop="goPage(to)">
      <slot />
  </a>
  <!-- <component v-bind="linkProps(to)">
    <slot />
  </component> -->
</template>

<script>
import { isExternal } from '@/utils/validate';

export default {
  props: {
    to: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'a'
    }
  },
  methods: {
	goPage(url){
		if (isExternal(url)) {
			window.open(url)
		}else{
			this.$router.push({path:url},onAbort=>{if(onAbort) console.log('')})
		}
	},
    linkProps(url) {
      if (isExternal(url)) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {
        is: 'router-link',
        to: url,
        tag: this.tag
      };
    }
  }
};
</script>
