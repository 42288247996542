import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

import { SGCookies } from '@/service/lib/localToken'


import '@/assets/icon/iconfont.css'
import '@/assets/icon/iconfont.js'

import request from '@/service/getData' //全局接口获取
import * as base from '@/utils/base' //静态文本
import * as utils from '@/utils/utils' //全局方法

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'normalize.css/normalize.css'
import '@/assets/css/global.scss'


import '@/router/permission'

// 动态加载阿里云字体库
// let iconfontVersion = ['font_3195233_mdthqf32iud']
// let iconfontUrl = `//at.alicdn.com/t/font_3195233_mdthqf32iud.css`
// iconfontVersion.forEach(ele => {
//     utils.loadStyle(iconfontUrl.replace('$key', ele))
// })
// 动态加载阿里云字体库


const app = createApp(App);


app.config.globalProperties.$request = request;
app.config.globalProperties.$base = base;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$SGCookies = SGCookies;

app.use(ElementPlus);
app.use(router)
app.use(store)
app.mount('#app')
