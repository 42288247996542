<!-- @format -->

<template>
  <div id="indexlayout-left" class="leftLay" :class="{ narrow: isCollapse }">

    <div class="lef_avatar">
      <div class="lef_avatar_img_fa">
        <!-- <img class="lef_avatar_logo" src="@/assets/images/home/bottomlogo.png" alt=""> -->

        <svg style="width:40px;height:40px;" aria-hidden="true">
          <use xlink:href="#icon-logo"></use>
        </svg>
        <img class="lef_avatar_img" src="@/assets/images/logo_w.png" />
      </div>
    </div>
    <div class="indexlayout-left-menu">
      <el-menu 
        :default-active="getSidebarMenuActive" :collapse="isCollapse" :collapse-transition="false">
        <template v-if="!siteTopNavEnable">
          <sidebar-menu-item v-for="route in permission_routes" :key="route.path" :routes="route"
            :base-path="route.path" :resolve-path="route.path" :active-top-menu="getTopMenuActive" />
        </template>
      </el-menu>
    </div>

    <div class="cont_size_box">
      <el-progress :percentage="resourceSpace.percentage" color="#FFCF2D" :format="format"></el-progress>
      <div class="use_wrap">
        <div>使用容量</div>
        <div class="use_wrap-text">
          <span>{{ resourceSpace.size }}</span>
          <span>/1G</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import variables from '@/assets/css/variables.scss'
import SidebarMenuItem from '@/layout/components/SidebarMenuItem'
import { getBelongTopMenuPath, getActiveSidebarMenuPath } from '@/utils/permission'
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'LayoutIndexLeft',
  components: {
    SidebarMenuItem,
    
  },
  computed: {
    ...mapGetters(['permission_routes',  'siteTopNavEnable', 'userInfo', 'sidebarOpened', 'resourceSpace']),
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebarOpened
    },
    getSidebarMenuActive: function () {
      const route = this.$route
      return getActiveSidebarMenuPath(route)
    },
    getTopMenuActive() {
      let route = this.$route
      return getBelongTopMenuPath(route)
    }
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    let isMyBase = ref(false)
    let size = ref(0)
    let addMindVisible = ref(false)
    let resourceId = ref(null)
    let isSeeFile = ref(false)
    let tableData = reactive({ data: [] })

    const getSpace = () => {
      store.dispatch('user/getSpace')
    }
    const myBase = () => {
      let routeUrl = router.resolve({
        path: `/myBase`
      })
      window.open(routeUrl.href, '_blank')
    }

    const selectMeun = (path) => {
      router.push({
        path: path
      })
    }

    const format = () => {
      return ''
    }

    onMounted(() => {
      getSpace()
    })
    return {
      isMyBase,
      size,
      addMindVisible,
      resourceId,
      isSeeFile,
      tableData,
      getSpace,
      myBase,
      selectMeun,
      format
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/variables.scss';

#indexlayout-left {
  position: relative;
  z-index: 12;
  width: $leftSideBarWidth;
  min-width: $leftSideBarWidth;
  height: 100%;
  transition-duration: 0.1s;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $mainLine;
  background: #F5F5F5;


  .indexlayout-left-menu {
    flex: 1;
    display: flex;
    overflow: auto;

    ul {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    :deep() .el-menu-item {
      margin: 20px 10px;
      height: 50px;

      i {
        width: 30px;
        height: 30px;
        background: transparent;
        border-radius: 4px;
        align-items: center;
        box-sizing: border-box;
        line-height: 30px;
        text-align: center;
      }

      span {
        margin-left: 15px;
      }

      &:hover {
        background: transparent;
      }
    }

    :deep() .el-menu-item.is-active {
      
      background: #E2E2E2;
    }

    :deep() i:first-child {
      margin-right: 0;
      margin-left: 10px;
      font-size: 20px;
    }

  }

  .cont_size_box {
    padding:0 20px;
    margin-bottom: 20px;

    :deep() .el-progress-bar {
      padding-right: 0px;
      .el-progress-bar__outer{
        background-color: #d7d6d6;
      }
    }

    :deep() .el-progress__text{
       min-width: 0;
    }

    .use_wrap {
      display: flex;
      margin-top: 6px;
      color: #999999;

      .use_wrap-text {
        display: flex;
        margin-left: auto;
      }
    }
  }


}

.lef_avatar {
  padding: 25px 0;
  color: $fontColor;
  text-align: center;

  .avatar {
    width: 40px;
    height: 40px;
    margin-top: 44px;
  }

  .lef_avatar_img_fa {
    display: flex;
    align-items: center;
    justify-content: center;

    .lef_avatar_logo {
      width: 27px;
      height: 27px;
    }

    .lef_avatar_img {
      width: 100px;
      height: 14px;
      margin-left: 5px;
    }
  }
}
</style>
