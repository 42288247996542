/**
 * 路由 主入口
 *           {
 *             meta: { title: '标题' },   必填
 *             linkpath: '/demo',         链接 -  /demo 内部路由 /^(https?:|mailto:|tel:)/.test(path)外链 , 可不写
 *           }
 *     ],
 *     activeMenu: '/example/list'  侧栏选中，如果设置路径，侧栏将突出显示你设置的路径，默认 router.path
 *     belongTopMenu: '/news'       所属顶级菜单,用于选中顶部菜单，与菜单切换，默认不设置 path.split('/') 第1个；
 *                                             三级此参数的作用是选中顶级菜单 ，二级此参数的作用是所属某个顶级菜单的下面，两个层级的必须同时填写一致
 *   }
 *
 * @format
 * @author 路由参数说明： path: '/home'                  链接 -  /home 内部路由 /^(https?:|mailto:|tel:)/.test(path)外链 hidden: true                   如果设置为 true ，项目将不会显示在 “导航栏” (默认为 false) redirect: ''                   重定向 name:'router-name'             路由名称 meta : { roles: ['admin','editor']    权限控制页面角色(您可以设置多个角色), 不设置此参数 默认 所有角色都有权限 title: 'title'               名称显示在侧栏和面包屑、浏览器title(推荐设置) icon: 'icon-class'           图标显示在侧栏中 breadcrumb: [                面包屑内容：1、默认不配置按照路由自动读取；2、false,按照路由自动读取讲不读取此；3、配置对应的面包屑格式如下：
 */


import { createRouter, createWebHistory } from 'vue-router'
import LayoutIndex from '@/layout/Index/LayoutMain'

// 引入Index框架

// 引入对应模块路由
// import adminRouter from '@/router/modules/adminRouter'; // 管理员路由
// import caseRouter from '@/router/modules/caseRouter'; // 案例

/**
 * 固定路由
 * 没有权限要求的路由
 * 所有角色可访问
 */
export const constantRoutes = [
  // {
  //   path: '/',
  //   redirect:{path: '/home'}
  // },
  // {
  //   path: '/home',
  //   component: () => import('@/views/home'),
  //   name: 'home',
  //   meta: { title: '句合逻辑', icon: 'icon-zuijin' }
  // },
  {
    path: '/login',
    meta: { title: '登录' },
    component: () => import('@/views/login'),
    hidden: true
  }, {
    path: '/introduce',
    meta: { title: '首页' },
    component: () => import('@/views/introduce'),
    hidden: true
  },
  {
    path: '/write',
    component: () => import('@/views/editor'),
    name: 'editTor',
    meta: { title: '创作' }
  },
  {
    path: '/moreResourceDetail',
    component: () => import('@/views/menu/moreResource/detail'),
    name: 'moreResourceDetail',
    meta: { title: '更多资源详情', icon: 'icon-chajian' }
  },
  {
    path: '/newsPage',
    meta: { title: '详情' },
    component: () => import('@/views/admin/newsPage'),
    hidden: true
  },
  {
    path: '/resourceDetail',
    meta: { title: '详情' },
    component: () => import('@/views/txt/resourcePage'),
    name: 'resourceDetail',
    hidden: true
  },
  {
    path: '/myBase',
    meta: { title: '个人信息' },
    name: 'myBase',
    component: () => import('@/views/mybase'),
    hidden: true
  },
  {
    path: '/404',
    meta: { title: 'Not Found' },
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/',
    component: LayoutIndex,
    redirect: '/home',
    name: '/',
    meta: { title: '', icon: 'home' },
    children: [
      {
        path: '/home',
        component: () => import('@/views/materialLibrary'),
        name: 'home',
        meta: { title: '素材库', icon: 'icon-sucaiku2' }
      },
      {
        path: '/creative',
        component: () => import('@/views/menu/creative'),
        name: 'creative',
        meta: { title: '创作', icon: 'icon-wodechuangzuo' }
      },
      {
        path: '/resource',
        component: () => import('@/views/menu/resource'),
        name: 'resource',
        meta: { title: '文件', icon: 'icon-wenjianguanli' }
      },

      {
        path: '/moreResource',
        component: () => import('@/views/menu/moreResource'),
        name: 'moreResource',
        meta: { title: '更多资源', icon: 'icon-gengduoziyuan1' }
      },

      {
        path: '/plugin',
        component: () => import('@/views/menu/plugin'),
        name: 'plugin',
        meta: { title: '插件', icon: 'icon-guanyuchajian', hideHeard: true }
      },

      {
        path: '/recycleBin',
        component: () => import('@/views/menu/recycleBin'),
        name: 'recycleBin',
        meta: { title: '回收站', icon: 'icon-huishouzhan2' }
      },
    ]
  },
  {
    path: '/materialList',
    component: () => import('@/views/materialLibrary/materialList'),
    name: 'materialList',
    hidden: true,
    meta: { title: '素材库', icon: 'icon-sucai' }
  },
]

/**
 * 异步路由
 * 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = [
  // 引入其他模块路由
  // ...adminRouter,
  // ...caseRouter,

  { path: "/:catchAll(.*)", redirect: '/404', hidden: true }
]


const router = createRouter({
  history: createWebHistory(), // 启用的话就是去除#
  scrollBehavior: () => ({ y: 0 }),
  // base: process.env.BASE_URL,
  base: '/', ///pc
  routes: constantRoutes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // 重置路由
}

export default router
