/**
 * 项目 Store
 *
 * @format
 * @author
 */

import { siteFiexdHeader, siteTopNavEnable, siteSidebarLogo, tagsView } from '@/settings'
const state = {
  siteFiexdHeader: siteFiexdHeader, // 是否固定右侧头部
  siteTopNavEnable: siteTopNavEnable, // 是否启用顶部导航
  siteSidebarLogo: siteSidebarLogo, // 是否显示侧边栏LOGO
  sidebarOpened: true, // 侧边栏是否展开
  tagsView: tagsView,
  topSearchShow: true, // 顶部搜索是否显示
  rightWidth:530, // 右侧侧边栏展开的宽度
  materialDropItem:{
    categoryId:"",
    type:"meun", //meun 菜单 material 素材
    changtype:"in",
  },//正在拖拽的素材的ID
}
const mutations = {
  // 设置是否固定右侧头部 Bval(true|false)
  SET_SITE_FIEXD_HEADER: (state, Bval) => {
    state.siteFiexdHeader = Bval
  },
  // 设置是否启用顶部导航 Bval(true|false)
  SET_SITE_TOPNAV_ENABLE: (state, Bval) => {
    state.siteTopNavEnable = Bval
  },
  // 设置是否显示侧边栏LOGO Bval(true|false)
  SET_SITE_SIDEBAR_LOGO: (state, Bval) => {
    state.siteSidebarLogo = Bval
  },
  // 侧边栏开关
  TOGGLE_SIDEBAR: state => {
    state.sidebarOpened = !state.sidebarOpened
  },
  // 页签显隐
  TOGGLE_TAGSVIEW: state => {
    state.tagsView = !state.tagsView
  },
  //设置右侧宽度
  SET_RIGHTWIDTH:  (state, Bval)  => {
    state.rightWidth = Bval
  },

  SET_MATERIALDROPITEM: (state, Bval) => {
    state.materialDropItem = Bval
  }
}
const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
