<!-- @format -->

<template>
  <div id="indexlayoutRightTop" class="indexlayout-right-top" :class="{'noBack':noBackground}">
    <div class="right-top-btn-home" @click="goHome" v-if="!messageShow">
      <img src="@/assets/images/logo_w.png" />
    </div>
    <div class="right-top-btn-item right-top-btn-item-user" v-if="messageShow">
      <div class="user-img" @click.stop="changeShow">
        <el-avatar class="avatar" :size="40" :src="userInfo.avatar" v-if="userInfo.avatar"></el-avatar>
        <el-avatar class="avatar" :size="40" :src="require('@/assets/images/img_02.png')" v-else></el-avatar>
      </div>
      <div class="user-text" @click.stop="changeShow">{{ userInfo.nickname }}</div>
      <div class="user-icon" @click.stop="changeShow" :class="{'is-active':showMore}">
        <i class="iconfont icon-zhankai"></i>
      </div>

      <div class="contextmenu user-opti" v-if="showMore">
        <a @click.stop="myBase">
          个人信息
        </a>
        <a @click.stop="logout">退出登录</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LayoutIndexRightTop',
  components: {

  },
  props: {
    messageShow: {
      type: Boolean,
      default: true
    },
    noBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMore: false
    }
  },
  computed: {
    ...mapGetters(['resourceSpace', 'userInfo']),
  },
  watch: {

  },
  mounted() {
    this.getSpace()
    let _this = this
    document.addEventListener("click", () => {
      _this.showMore = false
    })
  },
  methods: {
    changeShow() {
      this.showMore = !this.showMore
    },
    getSpace() {
      this.$store.dispatch('user/getSpace')
    },
    format() {
      return ''
    },
    goHome() {
      this.$router.push({
        path: `/home`
      })
    },
    myBase() {
      let routeUrl = this.$router.resolve({
        path: `/myBase`
      })
      this.showMore = false
      window.open(routeUrl.href, '_blank')
    },

    async logout() {
      this.showMore = false

      try {
        const { isExternal, siteLoginRouter } = await this.$store.dispatch('user/logout')
        if (!isExternal) {
          this.$router.push(siteLoginRouter + '?redirect=' + this.$route.fullPath)
        }
      } catch (error) {
        this.$message.error(error || 'Has Error')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/variables.scss';

.indexlayout-right-top {
  width: 100%;
  height: 70px;
  background: #FFFFFF;
  display: flex;

  .right-top-btn-home {
    height: 70px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: 20px;

    img {
      width: 100px;
    }
  }

  .right-top-btn-item {
    height: 60px;
    width: 144px;
    display: flex;
    margin-top: 10px;
    justify-content: center;

    &:first-child {
      margin-left: auto;
    }

    .use_wrap {
      display: flex;
      margin-top: 6px;
      color: #9C8989;

      .use_wrap-text {
        display: flex;
        width: 97px;
        justify-content: space-between;
      }
    }

    :deep() .el-progress-bar {
      padding-right: 24px;
    }

  }

  .right-top-btn-item-user {
    align-items: center;
    width: 220px;
    cursor: pointer;
    position: relative;
    justify-content: flex-end;

    .user-text {
      max-width: 120px;
      height: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      line-height: 32px;
      margin-left: 20px;
      font-size: 16px;
      color: #3F3A4B;

    }

    .user-icon {
      margin-right: 30px;

      transition: 100ms;
      margin-left: 10px;

      i {
        font-size: 14px;
      }
    }

    .user-icon.is-active {
      transform: rotate(-90deg);
      transition: 100ms;
    }

    .user-opti {
      position: absolute;
      top: 51px;
      right: 25px;
      z-index: 100;
    }
  }

  .right-top-btn-item-exit {
    align-items: center;

    cursor: pointer;

    &:hover {
      color: $buttonActiveColor;
    }

    .item-exit-text {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      margin-left: 10px;
    }

    .item-exit-icon {
      i {
        font-size: 20px;
      }

    }
  }

  .right-top-btn-dir {
    flex-direction: column;
    padding-left: 24px;
    box-sizing: border-box;
    width: 200px;
  }
}

.indexlayout-right-top.noBack {
  background-color: transparent;
}

@media (max-width:1600px) {
  #indexlayout-right-top {
    height: 60px;

    .right-top-btn-item {
      height: 60px;
    }
  }
}
</style>
