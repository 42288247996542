/**
 * 重置 axios
 *
 * @format
 * @author
 */

// import store from '@/store';
import { SGCookies, removeCookies, SGTorage } from '@/service/lib/localToken'
import axios from 'axios'
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import { ajaxHeadersTokenKey, serverLoginUrl, ajaxResponseNoVerifyUrl, siteTokenKey } from '@/settings'
import { isExternal } from '@/utils/validate'
// import { host } from '@/utils/base'

const qs = require('qs')
SGCookies('baseUrl', process.env.VUE_APP_APIHOST)
// 创建一个axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_APIHOST, // process.env.VUE_APP_APIHOST, // url = api url + request url
  withCredentials: false, // 当跨域请求时发送cookie
  timeout: 3500 // 请求超时时间,5000(单位毫秒) / 0 不做限制
})

// 全局设置 - post请求头
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/**
 * 关闭全局加载
 * 延迟200毫秒关闭，以免晃眼睛
 * @param target
 */
const closeLoading = target => {
  if (!target.config.loading) return true
  return new Promise(resolve => {
    setTimeout(() => {
      target.config.loading.close()
      resolve()
    }, 100)
  })
}
// 请求拦截器 - 在发送请求之前
service.interceptors.request.use(
  config => {
    // 如果设置了cType 说明是自定义 添加 Content-Type类型 为自定义post 做铺垫
    if (config.cType === 'form') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
      config.data = qs.stringify(config.data)
    } else if (config.cType === 'json') {
      config.headers['Content-Type'] = 'application/json'
    } else if (config.cType === 'multipart') {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    if (config.loading !== false) {
      config.loading = ElLoading.service({
        lock: true,
        background: 'transparent',
        spinner: '',
        text: ''
      })
    }
    let uuid = SGTorage('uuid')
    if (uuid) config.headers['uuid'] = uuid
    // 请根据实际情况修改
    // store.getters.token 加载时已在 [store/user] 用 SGCookies()获取Token
    // 让每个请求携带令牌
    // ajaxHeadersTokenKey -> ['X-Token'] 是自定义头key
    let token = SGCookies(siteTokenKey)
    if (token && serverLoginUrl !== config.url && config.url && config.url.indexOf('captcha/') < 0) config.headers[ajaxHeadersTokenKey] = token
    return config
  },
  error => {
    // 处理请求错误
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * 通过自定义代码确定请求状态
   * 这只是一个例子
   * 您还可以通过HTTP状态码来判断状态
   */
  async response => {
    await closeLoading(response)
    const res = response.data
    if (response.config.url.indexOf('.aliyuncs.com') > 0 || response.config.url.indexOf('oss.woollieemix.com') > 0) return res
    if (res.type && res.type.indexOf('application/') > -1) return res
    const { code } = res
    // 如果自定义代码不是200，则判断为错误。
    if (code !== '0000' && code !== 200) {
      // 获取替换后的字符串
      const reqUrl = response.config.url.split('?')[0].replace(response.config.baseURL, '')
      // console.log(reqUrl, 66666)
      const noVerifyBool = ajaxResponseNoVerifyUrl.includes(reqUrl)
      switch (code) {
        case 401: // 未登陆
          if (!noVerifyBool) {
            ElMessageBox({
              title: '提示',
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              message: '当前用户登入信息已失效，请重新登入再操作',
              beforeClose: () => {
                if (isExternal(serverLoginUrl)) {
                  window.location.href = serverLoginUrl
                } else {
                  removeCookies()
                  window.location.reload()
                }
              }
            })
          } else {
            ElMessage({ message: res.msg || 'Error', type: 'error', duration: 5000 })
          }
          break
        case 500:
          ElMessage({ message: res.msg || 'Error', type: 'error', duration: 5000 })
          break
        default:
          ElMessage({
            message: res.msg || 'Error',
            type: 'error',
            duration: 5 * 1000
          })
          break
      }

      // 返回错误 走 catch
      return Promise.reject(res)
      // return Promise.reject(new Error(res.msg || 'Error'));
      // return res;
    } else {
      return res
    }
  },
  async error => {
    console.log(error, 7777)
    await closeLoading(error)
    //console.log('err' + error.response.headers); // for debug
    //console.log('err' + error.response.data); // for debug
    //console.log('err' + error.response.status); // for debug
    ElMessage({
      message: error.msg || "网络异常！",
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
/**
 * 原 Axios 返回
 *
 * Method: get
 *     Request Headers
 *         无 - Content-Type
 *     Query String Parameters
 *         name: name
 *         age: age
 *
 * Method: post
 *     Request Headers
 *         Content-Type:application/json;charset=UTF-8
 *     Request Payload
 *         { name: name, age: age }
 *         Custom parameters
 *             { cType: true }  Mandatory Settings Content-Type:application/json;charset=UTF-8
 * ......
 */
export default service
